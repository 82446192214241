import type { LoginVerificationArgsSchema } from "@mono/validation/lib/Auth";
import type { AuthJwtPayload } from "@/utils/types/auth";
import type { APIOutputs, APIInputs } from "@/composables/useTrpcClient";

type userDetails = APIOutputs["user"]["details"]["data"];
type RequestPasswordResetMutationVariables =
  APIInputs["auth"]["requestPasswordReset"];
type PasswordResetMutationVariables = APIInputs["auth"]["submitPasswordReset"];

export const useAuthStore = defineStore("auth", () => {
  // states
  const userId = ref<string | undefined>(undefined);
  const adminRoles = ref<AuthJwtPayload["adminRoles"] | undefined>(undefined);
  const userDetails = ref<userDetails | undefined>(undefined);
  const { setTokens, isLoggedIn, resetTokens } = useAuthStorage();
  const router = useRouter();

  // reset handler
  const resetUser = () => {
    // reset auth local storage
    resetTokens();
    // reset userId
    userId.value = undefined;
    // reset userDetails
    userDetails.value = undefined;
  };

  // fetch user details handler
  const fetchUserDetails = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.user.details.query();

      return result;
    } catch (error) {
      console.error("Error fetching user details:", error);
      return undefined;
    }
  };

  const login = async (args: LoginVerificationArgsSchema) => {
    const { trpcClient } = useTrpcClient();
    try {
      const { token, refreshToken } =
        await trpcClient.auth.loginVerification.mutate(args, {
          context: {
            skipBatch: true,
          },
        });

      setTokens({ token, refreshToken });

      return { success: true };
    } catch (error) {
      console.error("Error logging in:", error);
      return { success: false };
    }
  };

  // Debounce the refreshTokens function to prevent multiple calls
  const refreshTokens = async () => {
    const { trpcClient } = useTrpcClient();
    const { authStorage } = useAuthStorage();

    const refreshToken = authStorage.value.refreshToken;

    try {
      if (refreshToken == null) {
        throw new Error("No refresh token found");
      }

      const tokens = await trpcClient.auth.refreshTokens.mutate(
        {
          refreshToken,
        },
        {
          context: {
            skipBatch: true,
          },
        }
      );

      setTokens({
        token: tokens.token,
        refreshToken: tokens.refreshToken,
      });

      return { success: true };
    } catch {
      logout();
      return { success: false };
    }
  };

  // Logout mutation
  const logout = () => {
    resetUser();

    router.replace({ name: "index" });
  };

  // request password reset mutation
  const requestPasswordReset = async ({
    email,
  }: RequestPasswordResetMutationVariables) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.auth.requestPasswordReset.mutate({
        email,
      });

      return {
        hasError: !result.success,
        success: true,
      };
    } catch (error) {
      console.error("Error requesting password reset:", error);
      return { success: false };
    }
  };

  // password reset  mutation
  const passwordReset = async ({
    linkUUID,
    newPassword,
  }: PasswordResetMutationVariables) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.auth.submitPasswordReset.mutate({
        linkUUID,
        newPassword,
      });

      return {
        hasVerified: result.success || false,
        success: true,
      };
    } catch (error) {
      console.error("Error submitting password reset:", error);
      return { success: false };
    }
  };

  const init = async () => {
    if (isLoggedIn.value && !userId.value) {
      const userDetails = await fetchUserDetails();

      if (userDetails) {
        userId.value = userDetails.data.id;
        adminRoles.value = userDetails.data.adminRoles;
      }
    }
  };

  return {
    // getters
    userId,
    userDetails,
    adminRoles,

    // setters
    login,
    refreshTokens,
    logout,
    requestPasswordReset,
    passwordReset,
    init,
  };
});
